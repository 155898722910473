import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    Link,
} from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Blog from './components/blog/Blog';
import Home from './components/home/Home';
import AllProjects from './pages/all-projects/AllProjects';
import AllBlogs from './pages/all-blogs/AllBlogs';
import SingleProject from './pages/single-project/SingleProject';
import GoToTop from './components/goToTop/GoToTop';

const Layout = () => (
    <>
        <Navbar />
        <Outlet />
        <GoToTop />
        <Footer />
    </>
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/projects',
                element: <AllProjects />,
            },
            {
                path: '/blogs',
                element: <AllBlogs />,
            },
            {
                path: '/blog/:id',
                element: <Blog />,
            },
            {
                path: '/project/:id',
                element: <SingleProject />,
            },
            {
                path: '*',
                element: <div style={{textAlign: 'center', color: '#000', padding: '100px 0 30px 0', fontSize: '1.25rem'}}>
					<h1>ارور 404</h1>
					<p>صفحه مورد نظر یافت نشد</p>
                    <Link style={{
                        textDecoration: 'none',
                        color: '#0e5ddd',
                    }} to={'/'}>صفحه اصلی</Link>
				</div>
            }
        ],
    }
]);

const App = () => {
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
};

export default App;