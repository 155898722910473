import './projects.css';
import projects from '../../projects';
import { useState } from 'react';
import Modal from '../modal/Modal';
import Project from '../project/Project';
import { Link } from 'react-router-dom';

const Projects = () => {
	const [show, setShow] = useState(false);
	const [projectID, setProjectID] = useState(null);

	const showModal = (id) => {
		setShow(prev => !prev);
		setProjectID(id);
	};

	return (
		<div className='projects'>
			<h3 className='title'>
				پروژه‌ها
			</h3>

			<div className='tiles'>
			{ projects.slice(0, 6).map( project => (
				<Project key={project.id} project={project} showModal={showModal} />
			)) }
			</div>
			<div className='all'>
				<Link className='all-link' to={'/projects'}>
				مشاهده همه
				</Link>
			</div>
			{ show && <Modal id={projectID} setShow={setShow} /> }
		</div>
	);
};

export default Projects;