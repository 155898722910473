import './header.css';
import { useState } from 'react';
import profile from '../../images/profile.jpg';
import TypeIt from 'typeit-react';
import cv from '../../Waterface.pdf';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const Header = () => {
	const [downloading, setDownloading] = useState(false);

    const handleDownloadClick = () => {
        setDownloading(true);
        setTimeout(() => {
            setDownloading(false);
        }, 5000);
    };

	return (
		<header className='header'>
			<div className='wrapper'>
				<div className='right'>
					<div className='profile'>
						<img className='profile-img' src={profile} alt='Profile' />
					</div>
					<h2 className='name'>علیرضا آبچهره</h2>
				</div>

				<div className='left'>
					<h2 className='specialty'>توسعه دهنده فرانت‌اند</h2>
					<div className='masteries'>
						<span>مسلط بر </span>
						<TypeIt
							getBeforeInit={(instance) => {
								instance.type('ری‌اکت')
									.pause(2000).delete(6)
									.pause(1000).type('جاوا اسکریپت').pause(2000)
									.delete(12).pause(1000)
									.type('گیت و گیت‌هاب').pause(2000)
									.delete(13).pause(1000)
									.type('TailWind').pause(2000)
									.delete(8).pause(1000)
									.type('بوت‌استرپ').pause(2000)
									.delete(9).pause(1000)
									.type('ابزار های مرتبط').pause(2000)
									.delete(15).pause(1000);

								return instance;
							}}
							options={{
								loop: true,
								waitUntilVisible: true,
								startDelay: 2000,
							}}
							as='span'
							style={{
								color: '#0AF'
							}}
						/>
					</div>
					<div className='contact-cv'>
						<button
							onClick={() => document.getElementById('contact').scrollIntoView({behavior: 'smooth', block: 'center'})}
							className='contact-btn'
							title='مشاهده اطلاعات تماس'
						>اطلاعات تماس</button>
						<a
							href={cv}
							download={true}
							className='cv'
							title='دانلود رزومه'
							onClick={handleDownloadClick}
						>
						{ downloading ?
							<span className='spinner'></span>
							:
							<>
							<span className='text'>دانلود رزومه</span>
							<FileDownloadOutlinedIcon className='icon' />
							</>
						}
						</a>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;