import './goToTop.css';

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const GoToTop = () => {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div
            onClick={goToTop}
            className='go-top'
        >
			<button
           		title='برو به ابتدای صفحه'
			>
                برگشت به بالا
                <KeyboardDoubleArrowUpIcon />
            </button>
		</div>
    )
};

export default GoToTop;