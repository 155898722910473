import { useState } from "react";
import './modal.css';
import projects from "../../projects";
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({setShow, id}) => {
	const project = projects.find(item => item.id === id);
	const [img, setImg] = useState(0);

	return (
		<div
			className="modal-bg"
			onClick={() => setShow(prev => !prev)}
		>
			<div className="modal-body" onClick={(e) => e.stopPropagation()}>
				<div
					className="main-img"
					style={{
						background: `url(${project.imgSrc[img]}) no-repeat center`,
						backgroundSize: 'contain'
					}}
				>
				</div>
				<div className="prev-next">
					<button 
						className="next"
						onClick={() => setImg(prev => prev + 1)}
						disabled={img < project.imgSrc.length - 1 ? false : true}
					>عکس بعدی</button>
					<button
						className="prev"
						onClick={() => setImg(prev => prev - 1)}
						disabled={img === 0 ? true : false}
					>عکس قبلی</button>
				</div>
				<div className="gallery">
					{ project.imgSrc.map( (img, index) => (
						<img
							key={img}
							src={img}
							alt="تصویر پروژه"
							onClick={() => (setImg(index))} />
					) ) }
				</div>

				<p className="title">{project.name}</p>
				{ !project.isFinished && <p className="is-finished">این پروژه به اتمام نرسیده و در حال توسعه می‌باشد!</p> }
				<p className="desc">{project.longDesc}</p>
				{ project.githubLink && 
					<a
						rel="noreferrer"
						target="_blank"
						className="github-link"
						href={project.githubLink}
					>لینک گیت‌هاب پروژه</a>
				}
				{ project.features &&
					<ul className="features">
						<p className="title">امکانات این پروژه:</p>
						{ project.features.map(feature => (
							<li className="feature" key={feature}>{feature}</li>
						)) }
					</ul>
				}
				{ project.usedTools && <ul className="tools">
					<p className="title">ابزار های استفاده شده در این پروژه:</p>
					{ project.usedTools?.map(tool => (
						<li className="tool" key={tool}>{tool}</li>
					)) }
				</ul> }
				{ project.link && 
					<a
						className="link"
						rel="noreferrer"
						target="_blank"
						href={project.link}
					>مشاهده به صورت آنلاین</a>
				}
				<button
					className="exit-btn"
					onClick={() => setShow(prev => !prev)}
				><CloseIcon /></button>
			</div>
		</div>
	);
};

export default Modal;