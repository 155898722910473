import './allProjects.css';
import projects from '../../projects';
import { Link } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect } from 'react';

const AllProjects = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	});

	return (
		<div className='all-projects'>
			<div className='wrapper'>
				<h2 className='title'>همه پروژه‌ها</h2>
				<div className='tiles'>
				{
					projects.map( project => (
						<div
							key={project.id}
							className='tile'
							style={{
									background: `url(${project.imgSrc[0]}) no-repeat center`,
									backgroundSize: 'cover',
								}}
						>
							<div className='content'>
								<p className='title'>{project.name}</p>
								<p className='desc'>{project.longDesc}</p>
							</div>
							<div className='sidebar'>
								{ project.githubLink &&
									<a
										title='مشاهده کد در گیت‌هاب'
										className='link'
										href={project.githubLink}
										rel='noreferrer'
										target='_blank'
									>
										<GitHubIcon className='icon' />
										<span>گیت‌هاب</span>
									</a>
								}
								{ project.link &&
									<a
										title='مشاهده به صورت آنلاین'
										className='link'
										rel='noreferrer'
										target='_blank'
										href={project.link}
									>
										<VisibilityOutlinedIcon className='icon' />
										<span>آنلاین</span>
									</a>
								}
								<Link
									to={`/project/${project.id}`}
									className='link'
									title='جزئیات بیشتر پروژه'
								>
									<InfoOutlinedIcon className='icon' />
									<span>جزئیات</span>
								</Link>
							</div>
						</div>
					))
				}
				</div>
			</div>
		</div>
	);
};

export default AllProjects;