import './navbar.css';
import logo from '../../images/logo/logo-no-background.png';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
	const menu = (action) => {
		document.querySelector('.nav-links').classList.toggle('show');
		document.querySelector('.span').style.display = action === 'open' ? 'unset' : 'none';
	};

	return (
		<nav className="navbar">
			<div className='wrapper'>
				<div className='logo-container'>
					<img className='logo' src={logo} alt='Logo' />
				</div>

				<ul	className='nav-links'>
					<li className='link' onClick={() => menu('close')}>
						<Link to={'/'}>صفحه اصلی</Link>
					</li>
					<li className='link' onClick={() => menu('close')}>
						<Link to={'/projects'}>پروژه‌ها</Link>
					</li>
					<li className='link' onClick={() => menu('close')}>
						<Link to={'/blogs'}>بلاگ‌ها</Link>
					</li>
				</ul>

				<button
					className='menu'
					onClick={() => menu('open')}
				>
					<MenuIcon />
				</button>

				<span
					className='span'
					onClick={() => menu('close')}
				></span>
			</div>
		</nav>
	);
};

export default Navbar;