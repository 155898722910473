import './singleProject.css';
import projects from '../../projects';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const SingleProject = () => {
	const id = useParams().id;
	const project = projects[id - 1];

	const [img, setImg] = useState(0);

	return (
		<div className='single-project'>
			<div className='wrapper'>
				<div className='slider'>
					<div
						className='main-img'
						style={{
							background: `url(${project.imgSrc[img]}) no-repeat center`,
							backgroundSize: 'contain'
						}}
					>
						<button
							className='btn next-btn'
							onClick={() => setImg(prev => prev + 1)}
							disabled={img <= project.imgSrc.length - 2 ? false : true}
							title='تصویر بعدی'
						><ArrowForwardIosIcon className='icon' /></button>
						<button
							className='btn prev-btn'
							onClick={() => setImg(prev => prev - 1)}
							disabled={img >= 1 ? false : true}
							title='تصویر قبلی'
						><ArrowBackIosIcon className='icon' /></button>
					</div>
					<div className='images'>
						{ project.imgSrc.map( (img, index) => (
							<img
								src={img}
								alt='تصویر پروژه'
								key={`img${index * 3.14}`}
								onClick={() => setImg(index)}
								className='img'
								loading='lazy'
							/>
						))
						}
					</div>
				</div>

				<div className='details'>
					<p className="title">{project.name}</p>
					{ !project.isFinished && <p className="is-finished">این پروژه به اتمام نرسیده و در حال توسعه می‌باشد!</p> }
					<p className="desc">{project.longDesc}</p>
					{ project.githubLink && 
						<a
							rel="noreferrer"
							target="_blank"
							className="github-link"
							href={project.githubLink}
						>لینک گیت‌هاب پروژه</a>
					}
					<ul className="features">
						<p className="title">امکانات این پروژه:</p>
						{ project.features.map(feature => (
							<li className="feature" key={feature}>{feature}</li>
						)) }
					</ul>
					{ project.usedTools && <ul className="tools">
						<p className="title">ابزار های استفاده شده در این پروژه:</p>
						{ project.usedTools?.map(tool => (
							<li className="tool" key={tool}>{tool}</li>
						)) }
					</ul> }
					{ project.link && 
						<a
							className="link"
							rel="noreferrer"
							target="_blank"
							href={project.link}
						>مشاهده به صورت آنلاین</a>
					}
				</div>
			</div>
		</div>
	);
};

export default SingleProject;