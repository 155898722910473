import './overall.css';
import { motion } from 'framer-motion';

const Overall = () => {
	const specialtyList = [
		{ name: 'HTML', value: 90 },
		{ name: 'CSS', value: 85 },
		{ name: 'JS', value: 70 },
		{ name: 'react', value: 50 },
		{ name: 'git/GitHub', value: 60 },
		{ name: 'tailWind', value: 75 },
		{ name: 'Bootstrap', value: 70 },
		{ name: 'firebase', value: 35 },
	];

	return (
		<motion.div
			className='overall'
			initial={{
				opacity: 0,
				scale: 0.5,
				y: 50
			}}
			whileInView={{
				opacity: 1,
				scale: 1,
				y: 0
			}}
			viewport={{once: true}}
			transition={{
				duration: 0.75,
				type: 'tween',
				stiffness: 75,
				delay: 0.25,
			}}
		>
			<p className='title'>اطلاعات کلی</p>

			<div className='details'>
				{ specialtyList?.map( item => (
					<div key={item.name} className="progress-bar"
						style={{
							background: `radial-gradient(closest-side, #fff 79%, transparent 80% 100%), conic-gradient(#0e5ddd ${item.value}%, #0af 0)`,
						}}
					>
						<span>{item.name}</span>
					</div>
				))				
				}
			</div>
		</motion.div>
	);
};

export default Overall;