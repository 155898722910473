import './home.css';
import Header from '../header/Header';
import Specific from '../specific/Specific';
import Overall from '../overall/Overall';
import Projects from '../projects/Projects';
import Contact from '../contact/Contact';
import { useEffect } from 'react';

const Home = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	})

	return (
		<div className='home'>
			<Header />

			<main className='main'>
				<div className='wrapper'>
					<div className='specialties'>
						<Specific />
						<Overall />
					</div>
					<Projects />
					<Contact />
				</div>
			</main>
		</div>
	);
};

export default Home;