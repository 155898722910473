import p11 from './images/projects/p1/11.png';
import p12 from './images/projects/p1/12.png';
import p13 from './images/projects/p1/13.png';
import p14 from './images/projects/p1/14.png';
import p15 from './images/projects/p1/15.png';
import p16 from './images/projects/p1/16.png';
import p17 from './images/projects/p1/17.png';

import p21 from './images/projects/p2/p21.png';

import p31 from './images/projects/p3/p31.png';

import p41 from './images/projects/p4/p41.png';
import p42 from './images/projects/p4/p42.png';

import p51 from './images/projects/p5/p51.png';

import p61 from './images/projects/p6/p61.png';
import p62 from './images/projects/p6/p62.png';

import p71 from './images/projects/p7/p71.png';
import p72 from './images/projects/p7/p72.png';
import p73 from './images/projects/p7/p73.png';
import p74 from './images/projects/p7/p74.png';
import p75 from './images/projects/p7/p75.png';

const projects = [
	{
		id: 1,
		usedLangs: ['html', 'css', 'js', 'react'],
		name: 'سایت فروشگاهی',
		shortDesc: 'سایت فروشگاهی با استفاده از ری‌اکت و سیستم مدیریت محتوا',
		longDesc: 'سایت فروشگاهی با استفاده از ری‌اکت و سیستم مدیریت محتوا Strapi',
		usedTools: [
			'کتابخانه framer-motion برای انیمیشن‌ها',
			'کتابخانه react-toatify برای اعلان',
			'sass برای استایل دهی',
			'axios برای مدیریت درخواست‌ها به سرور',
		],
		features: [
			'فیلتر محصولات بر اساس قیمت، جدیدترین‌ها و ...',
			'دسته‌بندی انواع محصولات',
			'ذخیره و ویرایش اطلاعات کاربر',
			'قرار دادن محصولات در لیست تخفیف‌دار و پر فروش‌ها',
			'مدیریت کامل همه محصولات توسط ادمین',
			'جست‌و‌جو بین همه محصولات در سایت',
			'لیست علاقه‌مندی‌ها',
		],
		githubLink: null,
		imgSrc: [p11, p12, p13, p14, p15, p16, p17],
		link: null,
		isFinished: true,
	},
	{
		id: 2,
		usedLangs: ['html', 'css', 'js', 'react'],
		name: 'سایت فیلم',
		shortDesc: 'سایت فیلم و سریال با ری‌اکت',
		longDesc: 'سایت فیلم و سریال کامل با استفاده از ری‌اکت و TMDB API',
		usedTools: [
			'Material-UI Icons',
			'Axios',
			'React Router Dom',
			'Redux',
			'Swiper Slider',
			'TMDB API',
			'VITE',
		],
		features: [
			'UI جذاب و چشمگیر',
			'فیلتر و دیدن لیست فیلم‌های دلخواه',
			'دسته بندی فیلم‌ها، هنرمندان و برنامه‌های تلویزیونی',
			'نمایش فیلم‌های مشابه و پیشنهادی',
			'ساخت حساب کاربری و ذخیره فیلم‌های مورد علاقه، ثبت امتیاز برای فیلم‌ها و ...',
			'جزئیات فیلم، بازیگر و برنامه تلویزیونی',
			'جست‌و‌جوی فیلم و بازیگران',
		],
		githubLink: null,
		imgSrc: [p71, p72, p73, p74, p75],
		link: null,
		isFinished: false,
	},
	{
		id: 3,
		usedLangs: ['html', 'css', 'js', 'react'],
		name: 'ToDo App',
		shortDesc: 'برنامه لیست کارها',
		longDesc: 'برنامه لیست کارها با استفاده از ری‌اکت',
		usedTools: [
			'آیکون‌های fontawesome',
		],
		features: [
			'ذخیره لیست در حافظه مرورگر',
			'امکان حذف، ویرایش، تنظیم اولویت، اضافه کردن توضیحات و تغییر به حالت انجام شده',
			'نمایش فقط کامل‌شده‌ها یا کامل‌نشده‌ها',
			'نمایش به ترتیب اولویت',
			'حالت تاریک یا روشن',
		],
		githubLink: 'https://github.com/Alireza-Waterface/React-ToDo-App',
		imgSrc: [p21],
		link: 'https://todo-wf.netlify.app/',
		isFinished: true,
	},
	{
		id: 4,
		usedLangs: ['html', 'css', 'js', 'react'],
		name: 'Drag and drop',
		shortDesc: 'برنامه Drag and Drop',
		longDesc: 'برنامه Drag and drop با استفاده از ری‌اکت',
		usedTools: null,
		features: [
			'نمایش سایز فایل',
			'نمایش آیکون متناسب با نوع فایل',
			'انتخاب هر تعداد فایل جهت آپلود',
		],
		githubLink: 'https://github.com/Alireza-Waterface/React-drag-and-drop',
		imgSrc: [p31],
		link: 'https://dragdrop-wf.netlify.app/',
		isFinished: true,
	},
	{
		id: 5,
		usedLangs: ['html', 'css', 'js', 'react'],
		name: 'Quote generator',
		shortDesc: 'برنامه Quote Generator',
		longDesc: 'برنامه Quote Generator با استفاده از ری‌اکت',
		usedTools: null,
		features: [
			'دارای تایمر',
			'قابلیت اشتراک گذاری از طریق شبکه‌های اجتماعی',
			'کپی کردن متن',
			'ذخیره متن‌ها در حافظه مرورگر',
			'نمایش اعلان برای کپی، حذف یا ذخیره',
		],
		githubLink: 'https://github.com/Alireza-Waterface/React-Quote-Generator',
		imgSrc: [p41, p42],
		link: null,
		isFinished: true,
	},
	{
		id: 6,
		usedLangs: ['html', 'css'],
		name: 'فرم لاگین',
		shortDesc: 'فرم لاگین شیشه‌ای',
		longDesc: 'فرم لاگین یا ثبت‌نام با ظاهر شیشه‌ای و انیمیشن جذاب',
		usedTools: null,
		features: [
			'انیمیشن جذاب',
			'ظاهر ساده و دلپسند',
			'افکت‌های زیبا',
			'کاملا قابل توسعه و تغییر جهت نمایش در سایت',
		],
		githubLink: 'https://github.com/Alireza-Waterface/glassmorphism-form',
		imgSrc: [p51],
		link: 'https://glassmorphism-wf.netlify.app/',
		isFinished: true,
	},
	{
		id: 7,
		usedLangs: ['html', 'css', 'js'],
		name: 'فرم لاگین و ثبت نام',
		shortDesc: 'فرم لاگین و ثبت نام',
		longDesc: 'فرم لاگین و ثبت‌نام با افکت جذاب ',
		usedTools: null,
		features: [
			'انیمیشن جذاب',
			'ظاهر دلپسند',
			'قابل توسعه',
			'نمایش پیغام خطا',
		],
		githubLink: 'https://github.com/Alireza-Waterface/login-signup',
		imgSrc: [p61, p62],
		link: 'https://login-signup-wf.netlify.app/',
		isFinished: true,
	},
];

export default projects;