import './contact.css';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Contact = () => {
	const socialData = [
        {
			id: 1,
            name: 'telegram',
            url: 'https://t.me/+989155706085'
        },
		{
			id: 2,
			name: 'github',
			url: 'https://github.com/Alireza-Waterface',
		},
		{
			id: 3,
			name: 'linkedin',
			url: 'https://www.linkedin.com/in/waterface',
		},
		{
			id: 4,
			name: 'instagram',
			url: 'https://instagram.com/waterface_ar',
		},
        {
			id: 5,
            name: 'whatsapp',
            url: 'https://wa.me/+989155706085'
        },
    ];

	return (
		<div className="contact" id='contact'>
			<h2 className='title'>راه های ارتباطی</h2>

			<ul>
				<li>
					<a
						className='phone'
						href='tel:+989155706085'
					>
					<span>تلفن تماس:</span>
					<span>09155706085</span>
					</a>
				</li>
				<li>
					<a
						className='email'
						href='mailto:alireza.waterface@outlook.com'
					>
					<span>آدرس ایمیل:</span>
					<span>Alireza.waterface@outlook.com</span>
					</a>
				</li>
			</ul>

			<div className='social'>
				{ socialData.map( item => (
					<div key={item.id}>
						<a href={item.url} rel='noreferrer' target='_blank' className={item.name}>
							<span></span>
							{
								item.name === 'telegram' ? <TelegramIcon className='icon' /> :
								item.name === 'whatsapp' ? <WhatsAppIcon className='icon' /> :
								item.name === 'instagram' ? <InstagramIcon className='icon' /> :
								item.name === 'linkedin' ? <LinkedInIcon className='icon' /> :
								<GitHubIcon className='icon' />
							}
						</a>
						<p>
							{
								item.name === 'telegram' ? 'تلگرام' :
								item.name === 'whatsapp' ? 'واتس‌اپ' :
								item.name === 'instagram' ? 'اینستاگرام' :
								item.name === 'linkedin' ? 'لینکدین' :
								'گیت‌هاب'
							}
						</p>
					</div>
				) ) }
			</div>
		</div>
	);
};

export default Contact;