import './footer.css';
import { Link } from 'react-router-dom';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    const socialData = [
        {
            id: 1,
            name: 'telegram',
            url: 'https://t.me/+989155706085'
        },
        {
            id: 2,
            name: 'whatsapp',
            url: 'https://wa.me/+989155706085'
        },
        {
            id: 3,
            name: 'linkedin',
            url: 'https://www.linkedin.com/in/waterface',
        },
        {
            id: 4,
            name: 'instagram',
            url: 'https://instagram.com/waterface_ar',
        },
        {
            id: 5,
            name: 'github',
            url: 'https://github.com/Alireza-Waterface',
        },
    ];

    return (
        <footer className='footer'>
            <div className='wrapper'>
                <div className='right'>
                    <h4 className='title'>لینک‌ها</h4>
                    <ul className='list'>
                        <li className='item'>
                            <Link className='link' to={'/'}>صفحه اصلی</Link>
                        </li>
                        <li className='item'>
                            <Link className='link' to={'/projects'}>پروژه‌ها</Link>
                        </li>
                        <li className='item'>
                            <Link className='link' to={'/blogs'}>بلاگ‌ها</Link>
                        </li>
                    </ul>
                </div>

                <div className='center'>
                    <div className='container'>
                        <div className='dot'></div>
                        <div className='out1'></div>
                        <div className='out2'></div>
                        <div className='out3'></div>
                    </div>
                </div>

                <div className='left'>
                    <address className='contact-info'>
                        <h3 className='title'>طراحی و توسعه از علیرضا آبچهره</h3>

                        <ul>
                            <li>
                                <a
                                    className='phone'
                                    href='tel:+989155706085'
                                >تلفن تماس: 09155706085</a>
                            </li>
                            <li>
                                <a
                                    className='email'
                                    href='mailto:alireza.waterface@outlook.com'
                                >آدرس ایمیل: Alireza.waterface@outlook.com</a>
                            </li>
                        </ul>

                        <div className='social'>
                            { socialData.map( item => (
                                <div key={item.id}>
                                    <a
                                        href={item.url}
                                        rel='noreferrer'
                                        target='_blank'
                                        className={item.name}
                                    >
                                        <span></span>
                                        {
                                            item.name === 'telegram' ? <TelegramIcon className='icon' /> :
                                            item.name === 'whatsapp' ? <WhatsAppIcon className='icon' /> :
                                            item.name === 'instagram' ? <InstagramIcon className='icon' /> :
                                            item.name === 'linkedin' ? <LinkedInIcon className='icon' /> :
                                            <GitHubIcon className='icon' />
                                        }
                                    </a>
                                    <p>
                                        {
                                            item.name === 'telegram' ? 'تلگرام' :
                                            item.name === 'whatsapp' ? 'واتس‌اپ' :
                                            item.name === 'instagram' ? 'اینستاگرام' :
                                            item.name === 'linkedin' ? 'لینکدین' :
                                            'گیت‌هاب'
                                        }
                                    </p>
                                </div>
                            )) }
                        </div>
                    </address>
                </div>
            </div>
        </footer>
    );
};

export default Footer;