import './specific.css';
import { motion } from 'framer-motion';

const Specific = () => {
	const data = [
		{
			id: 1,
			title: 'زبان ها و فریم‌ورک',
			items: [
				'React',
				'JavaScript',
				'HTML',
				'CSS',
			],
		},
		{
			id: 2,
			title: 'ابزار ها',
			items: [
				'React-Router-Dom',
				'TailWind',
				'Bootstrap',
				'Git / GitHub',
				'SCSS',
				'NPM',
			],
		},
		{
			id: 3,
			title: 'مهارت‌های مرتبط',
			items: [
				'OnPage SEO',
				'APIs',
				'Responsive design',
				'CMS',
				'DataBase',
			],
		},
		{
			id: 4,
			title: 'زبان‌ها',
			items: [
				'English (B2)',
			],
		},
	];

	return (
		<motion.div
			className='specific'
			initial={{
				opacity: 0,
				scale: 0.5,
				x: 100
			}}
			whileInView={{
				opacity: 1,
				scale: 1,
				x: 0
			}}
			viewport={{once: true}}
			transition={{
				duration: 0.5,
				type: 'tween',
				stiffness: 75,
				delay: 0.5,
			}}
		>
			<p>با سلام، علیرضا آبچهره هستم. دانشجوی ترم ششم دوره کارشناسی رشته مهندسی کامپیوتر در دانشگاه صنعتی کرمانشاه.</p>
			<p>بیش از 1 سال است که در حوزه توسعه وب و به صورت تخصصی فرانت‌اند مشغول به فعالیت هستم.</p>

			{ data.map( info => (
				<div key={info.id}>
					<p className='title'>{info.title}</p>
					<div>
						{ info.items.map( item => (
							<span key={item}>{item}</span>
						))
						}
					</div>
				</div>
			))
			}
		</motion.div>
	);
};

export default Specific;