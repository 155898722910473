import './project.css';

const Project = ({project, showModal}) => {
	return (
		<div className='tile'>
			<div
				className='img-bg'
				style={{
					background: `url(${project.imgSrc[0]}) no-repeat center`,
					backgroundSize: 'cover',
				}}
			></div>
			<div className='details'>
				<button
					onClick={() => showModal(project.id)}
				>اطلاعات بیشتر</button>
				<p>{project.name}</p>
			</div>
		</div>
	);
};

export default Project;