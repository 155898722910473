import './allBlogs.css';

const AllBlogs = () => {


	return (
		<div className="all-blogs">
			<div className="wrapper">
				<h1 className="title">همه بلاگ‌ها</h1>
			</div>
		</div>
	);
};

export default AllBlogs;